@import "src/styles/variables";
@import "src/styles/mixins";

.notification-banner {
  position: relative;
  background-color: $black;
  top: $header-height-mobile;
  z-index: 4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;

  .click-area {
    color: $lemon-green;
    font-size: 12px;
    text-align: center;
    padding: 0 12px;
    margin: auto;

    a {
      text-decoration: none;
      color: inherit;

      p {
        display: inline;
        color: inherit;
        font-size: 14px;
        letter-spacing: 0.009rem;

        &.title {
          font-weight: bolder;
          font-family: $font-semibold;
          margin-right: 5px;
        }
      }
    }
  }

  @include for-vertical-tablet-up {
    top: $header-height-tablet;
  }

  @include for-desktop-up {
    top: $header-height-desktop;
  }
}
