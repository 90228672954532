@import "src/styles/variables";
@import "src/styles/mixins";

.products-carousel {
  width: 100%;
  background: $light-grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 76px;

  h1 {
    text-align: center;
    font-size: 28px;
  }

  .slick-list {
    padding: 35px 0 82px !important;

    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }

  .products-carousel-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: $header-height-mobile;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;

    .backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      background: $dark-grey;
      opacity: 0.2;
      z-index: -1;
    }

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  @include for-vertical-tablet-up {
    h1 {
      font-size: 40px;
    }

    .products-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 44px;
      padding: 47px 0 152px;
    }
  }

  @include for-desktop-up {
    .products-list {
      display: flex;
      column-gap: 35px;
    }
  }
}
